<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区 -->
      <el-row :gutter="20">
        <!-- 添加商品分类 -->
        <el-col :span="4">
          <el-button type="primary" @click="getAddGoods"
            >+ 添加商品分类</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="goodsList" style="width: 100%" stripe border>
        <el-table-column prop="id" label="id" width="100"> </el-table-column>
        <!-- <el-table-column prop="icon"
                         label="分类图标">
          <template slot-scope="scope">
            <img class="logoImg"
                 :src="scope.row.icon" />
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="icon" label="分类图标">
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.icon"
            >
              <img :src="scope.row.icon" width="200" height="200" />
              <img
                slot="reference"
                :src="scope.row.icon"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="分类名称"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="editProduct(scope.row)"
              >修改</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteGoods(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        @size-change="handleSizeChange"
        :current-page="queryInfo.pageNo"
        :page-size="queryInfo.limit"
        :page-sizes="[15, 30, 50, 100]"
        @current-change="handleCurrentChange"
        layout="total,sizes,prev,pager,next,jumper"
      ></el-pagination>
    </el-card>
    <!-- 添加商品分类 -->
    <el-dialog
      title="添加商品分类"
      :visible.sync="AddDialogVisible"
      width="30%"
      @close="AddDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        ref="AddFormRef"
        :model="AddProductForm"
        :rules="AddFormRules"
        label-width="80px"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="AddProductForm.name"
            clearable
            @input="input($event)"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品图标" prop="icon">
          <el-upload
            class="avatar-uploader"
            action="/api/v1/file/file"
            :data="this.bizType1"
            :before-upload="beforeUpload"
            :on-success="handlesuccess1"
            :show-file-list="false"
          >
            <img
              v-if="AddProductForm.icon"
              :src="AddProductForm.icon"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getAddProduct">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改商品分类 -->
    <el-dialog
      title="修改商品分类"
      :visible.sync="editDialogVisible"
      width="30%"
      @close="editDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        ref="editFormRef"
        :model="editProductForm"
        :rules="editFormRules"
        label-width="80px"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
            v-model="editProductForm.name"
            clearable
            @input="input($event)"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品图标" prop="icon">
          <el-upload
            class="avatar-uploader"
            action="/api/v1/file/file"
            :data="this.bizType1"
            :before-upload="beforeUpload"
            :on-success="handlesuccess2"
            :show-file-list="false"
          >
            <img
              v-if="editProductForm.icon"
              :src="editProductForm.icon"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getEditProduct">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1,
        name: ''
      },
      total: 0,
      goodsList: [],
      // 添加商品分类的对话框显示与隐藏
      AddDialogVisible: false,
      // 添加商品分类的表单
      AddProductForm: {
        name: '',
        // icon: '',
        parentId: 0,
        sortIndex: undefined
      },
      // 添加商品分类的校验规则
      AddFormRules: {
        name: [
          { required: true, message: '请输入商品类别名称', trigger: 'blur' }
        ],
        parentId: [
          { required: true, message: '请输入类别等级', trigger: 'blur' }
        ],
        sortIndex: [{ required: true, message: '请输入排序', trigger: 'blur' }]
        // icon: [{ required: true, message: '请输入商品图标', trigger: 'blur' }]
      },
      productClass: [],
      imageUrl: {
        icon: ''
      },
      bizType1: {
        bizType: 'goodsStyle'
      },
      editDialogVisible: false,
      // 修改商品分类的表单
      editProductForm: {
        name: '',
        icon: '',
        parentId: 0,
        sortIndex: undefined
      },
      // 修改商品分类的校验规则
      editFormRules: {
        name: [
          { required: true, message: '请输入商品类别名称', trigger: 'blur' }
        ],
        parentId: [
          { required: true, message: '请输入类别等级', trigger: 'blur' }
        ],
        sortIndex: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        icon: [{ required: true, message: '请输入商品图标', trigger: 'blur' }]
      },
      // 修改 商品分类的id
      editId: '',
      productName: '',
      // productPrice: ''
      classId: ''
    }
  },
  created() {
    this.getGoodsClass()
  },
  methods: {
    // 商品分类表
    async getGoodsClass() {
      const res = await this.$http.get('productCategory/list', {
        params: this.queryInfo
      })
      // this.goodsList = res.data.data.data.map((item) => ({
      //   ...item,
      //   newstatus: item.status === 0 ? '有货' : '缺货'
      // }))
      this.goodsList = res.data.data.data
      this.total = res.data.data.total
      // console.log(res, 1111)
    },
    // 跳转到分类详情
    getCheckProduct(id) {
      this.$router.push(`/goodsClassList/${id}`)
    },
    // 监听 limit 改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getGoodsClass()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getGoodsClass()
    },
    // 添加商品分类
    getAddGoods() {
      this.AddDialogVisible = true
    },
    // 提交添加商品分类对话框
    getAddProduct() {
      this.$refs.AddFormRef.validate(async valid => {
        if (!valid) return
        const res = await this.$http.post(
          '/productCategory',
          this.AddProductForm
        )
        // console.log(res)
        this.getGoodsClass()
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('添加商品分类成功')
        this.AddDialogVisible = false
      })
    },
    // 监听添加分类对话框的关闭
    AddDialogClosed() {
      this.$refs.AddFormRef.resetFields()
      this.imageUrl.icon = ''
    },
    beforeUpload(file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    handlesuccess1(response, file, fileList) {
      this.$forceUpdate()
      this.AddProductForm.icon = response.data
    },
    handlesuccess2(response, file, fileList) {
      this.$forceUpdate()
      this.editProductForm.icon = response.data
    },
    // 修改商品分类
    editProduct(detail) {
      this.editId = detail.id
      this.editProductForm.name = detail.name
      this.editProductForm.icon = detail.icon
      this.editDialogVisible = true
    },
    // 提交修改商品分类
    getEditProduct() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const res = await this.$http.put(
          `/productCategory/${this.editId}`,
          this.editProductForm
        )
        // console.log(res)
        this.getGoodsClass()
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('修改商品分类成功')
        this.editDialogVisible = false
      })
    },
    // 监听修改分类对话框的关闭
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
      this.imageUrl.icon = ''
    },
    // 删除商品分类
    async deleteGoods(id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除商品分类，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`/productCategory/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除失败')
      }
      // console.log(res)
      this.$message.success('删除成功')
      this.getGoodsClass()
    },
    input(e) {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.logoImg {
  width: 80px;
  height: 80px;
}
.avatar {
  width: 80px;
  height: 80px;
}
// img {
//   width: 80px;
//   height: 80px;
// }
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  // width: 178px;
  // height: 178px;
  // border: 1px solid #000;
  display: block;
  object-fit: cover;
}
</style>
